import React from "react";
import { Box, Center, Heading, Text, Image } from "@chakra-ui/react";
import Footer from "../components/Footer";

const AboutPage = () => {
  return (
    <Box
      style={{
        backgroundImage: "linear-gradient(180deg, #ffffff 20%, #00eaff 100%)",
      }}
      minHeight="100vh"
      padding={{ base: "2rem", md: "6rem" }}
    >
      <Center>
        <Box
          backgroundColor="white"
          borderRadius="md"
          boxShadow="0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06)"
          padding="1rem"
          width={{ base: "100%", md: "100%" }}
          textAlign={"center"}
          
        >
          <Heading as="h1" marginBottom="1rem">
            About Me
          </Heading>
          <Text fontSize={{ base: "xl", md: "2xl" }}>
            In the realm of robotics, Pratik thrives, A skilled engineer, his talents derive. 
            </Text>
            <Text fontSize={{ base: "xl", md: "2xl" }}>
              From startups to projects, his expertise shines, He's mastered the code, the circuit, the sketch lines.
            </Text>
            <Text fontSize={{ base: "xl", md: "2xl" }}>
As DishCare's co-founder, his creation began, A robotic dishwasher, a technologic plan. 
</Text>
<Text fontSize={{ base: "xl", md: "2xl" }}>
  Efficient and innovative, it's clear to see, Two patents pending, it's a breakthrough, indeed.
</Text>
<Text fontSize={{ base: "xl", md: "2xl" }}>
At Skyven Technologies, he took a stand, Built solar heating receivers, achievement so grand. 
</Text>
<Text fontSize={{ base: "xl", md: "2xl" }}>
  Reliability and automation were explored, Systems and process, harmoniously restored.
</Text>
<Text fontSize={{ base: "xl", md: "2xl" }}>
Promaxo welcomed his advanced skill, Creating MRI machines with undeniable will. 
</Text>
<Text fontSize={{ base: "xl", md: "2xl" }}>
Managing and collaborating in perfect harmony, His electronics prowess, evident sincerely.
</Text>
<Text fontSize={{ base: "xl", md: "2xl" }}>
Purdue University, his knowledge greater grew, Degrees achieved, both Master's and Bachelor's too. 
</Text>
<Text fontSize={{ base: "xl", md: "2xl" }}>
  In research and teaching, his wisdom yields, Machines and insights, like treasures unveiled.
</Text>
<Text fontSize={{ base: "xl", md: "2xl" }}>
A multitude of skills, Pratik certainly bears, From programming to 3D design, he confidently declares.
</Text> 
<Text fontSize={{ base: "xl", md: "2xl" }}>
This engineer's journey, onward will he roam, Forging the future, with passion of his own.
          </Text>
        </Box>
      </Center>
      <Footer />
    </Box>
  );
};

export default AboutPage;