import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Textarea,
} from "@chakra-ui/react";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic (e.g. API call)
  };

  return (
    <Box as="form" onSubmit={handleSubmit} width="100%">
      <Grid templateColumns={["1fr 1fr"]} gap={4}>
        <GridItem>
          <FormControl id="name" marginBottom={4}>
            <FormLabel>Name:</FormLabel>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl id="email" marginBottom={4}>
            <FormLabel>Email:</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FormControl>
        </GridItem>
      </Grid>
      <FormControl id="message" marginBottom={4}>
        <FormLabel>Message:</FormLabel>
        <Textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
      </FormControl>
      <Button type="submit" colorScheme="teal">
        Submit
      </Button>
    </Box>
  );
};

export default ContactForm;