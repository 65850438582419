import { ChakraProvider } from "@chakra-ui/react";
import { Global, css } from "@emotion/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import PortfolioPage from "./pages/PortfolioPage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import Navbar from "./components/Navbar";


function App() {
  return (
    <ChakraProvider>
      <Global
        styles={css`
          html,
          body {
            margin: 0;
            padding: 0;
          }
        `}
      />
      <Router>
      <Navbar />
      
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;