import React, { useEffect } from "react";
import { Box, Heading, Link, Text } from "@chakra-ui/react";
const ContactInfo = () => {
  useEffect(() => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "https://assets.calendly.com/assets/external/widget.css";
    document.head.appendChild(link);

    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.head.removeChild(link);
      document.body.removeChild(script);
    };
  }, []);

  const openCalendlyWidget = () => {
    window.Calendly && window.Calendly.initPopupWidget({ url: "https://calendly.com/pratik-chawla/30min" });
    return false;
  };

   return (
    <Box>
      <Heading as="h3" size="lg" marginBottom="1rem">
        Want to reach out?
      </Heading>
      <Text>Phone: (765) 637-6628</Text>
      <Text>Email: pratik.chawla42@gmail.com</Text>
      <Text>
        Schedule a meeting:{" "}
        <Link onClick={openCalendlyWidget} color="teal.500" href={false}>
          Book here
        </Link>
      </Text>
      <Text>
        Social Media:{" "}
        <Link href="https://twitter.com/patialaprats" color="teal.500">
          Twitter
        </Link>{" "}
        |{" "}
        <Link href="https://linkedin.com/in/pratik-chawla" color="teal.500">
          Linkedin

        </Link>
      </Text>

    </Box>
  );
};

export default ContactInfo;