
import { Box, Flex, Link, Spacer, useColorModeValue } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const Navbar = () => {
 
  const textColor = useColorModeValue("gray.700", "gray.200");

  return (
    <Flex
      as="nav"
      padding={4}
      boxShadow="md"
      zIndex={10}
    >
      <Link as={RouterLink} to="/" fontWeight="bold" fontSize="xl" color={textColor}>
        Pratik Chawla
      </Link>
      <Spacer />
      <Box>
        <Link
          as={RouterLink}
          to="/portfolio"
          marginLeft={4}
          fontWeight="medium"
          color={textColor}
          _hover={{ textDecoration: "underline", color: "teal.500" }}
        >
          Portfolio
        </Link>
        <Link
          as={RouterLink}
          to="/about"
          marginLeft={4}
          fontWeight="medium"
          color={textColor}
          _hover={{ textDecoration: "underline", color: "teal.500" }}
        >
          About
        </Link>
        <Link
          as={RouterLink}
          to="/contact"
          marginLeft={4}
          fontWeight="medium"
          color={textColor}
          _hover={{ textDecoration: "underline", color: "teal.500" }}
        >
          Contact
        </Link>
      </Box>
    </Flex>
  );
};

export default Navbar;