import React from "react";
import { Box, Center, Grid, Stack, Image } from "@chakra-ui/react";
import ContactForm from "../components/ContactForm";
import ContactInfo from "../components/ContactInfo";
import Footer from "../components/Footer";

const ContactPage = () => {
  return (
    <Box
      style={{
        backgroundImage: "linear-gradient(180deg, #ffffff 20%, #00eaff 100%)",
      }}
      minHeight="100vh"
      padding={{ base: "2rem", md: "6rem" }}
    >
      <Center>
        <Box
          backgroundColor="white"
          borderRadius="md"
          boxShadow="0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06)"
          padding="1rem"
          width={{ base: "100%", md: "auto" }}
        >
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={4}
            alignItems="center"
            marginBottom="1rem"
          >
            <Image
              boxSize="150px"
              borderRadius="full"
              src="pratik.png"
              alt="Profile Image"
            />
            <ContactInfo />
          </Stack>
          <Grid templateColumns={["1fr", "1fr, 1fr)"]} gap={8}>
            <ContactForm />
          </Grid>
        </Box>
      </Center>
      <Footer />
    </Box>
  );
};

export default ContactPage;