import React from "react";
import { Box, Text} from "@chakra-ui/react";

const Footer = () => (
    <Box
      as="footer"
      display="flex"
      justifyContent="center"
      alignItems="center"
      backgroundColor="transparent"
      padding="1rem"
      marginTop="2rem"
    >
      <Text color="blue.900">Created by Pratik Chawla &copy; {new Date().getFullYear()}</Text>
    </Box>
  );

  export default Footer;