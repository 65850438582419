import React, { useEffect } from "react";
import { Box, Heading, Text, Button, VStack, Flex, Spacer, Link, Image, HStack, IconButton, useBreakpointValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
// import Copyright from "../components/Copyright";
// import WaterEffect from '../components/WaterEffect';
import Footer from "../components/Footer";

const HomePage = () => {
  const navigate = useNavigate();
  const fontSize = useBreakpointValue({ base: "md", md: "xl" });
  const spacing = useBreakpointValue({ base: 2, md: 4 });

  const handleViewPortfolio = () => {
    navigate("/portfolio");
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Box
  style={{
    backgroundImage: "linear-gradient(135deg, #ffffff 30%, #00eaff 100%)",
  }}
  minHeight="100vh"
  
>
{/* <WaterEffect /> */}
        <VStack spacing={spacing} alignItems="center" justifyContent="center" minHeight="calc(100vh - 2rem)">
        <Image boxSize="150px" borderRadius="full" src="pratik.png" alt="Profile Image" />
        <Heading as="h1">Pratik Chawla</Heading>
        <Text fontSize={fontSize}>Roboticist | Mechanical Engineer</Text>
        <Text fontSize={fontSize}>And now, Web Developer!</Text>
        <Text maxWidth="sm" textAlign="center" fontSize="md">
          Passionate about robotics and mechanical design. With 5 years of experience in the industry, I'm dedicated to creating innovative solutions and improving the world of robotics.
        </Text>
        <Button colorScheme="teal" onClick={handleViewPortfolio}>
          View Portfolio
        </Button>
        <HStack>
          <IconButton as="a" href="https://github.com/pratsc7117" icon={<FaGithub />} aria-label="GitHub" _hover={{ backgroundColor: "gray.100" }} />
          <IconButton as="a" href="https://linkedin.com/in/pratik-chawla" icon={<FaLinkedin />} aria-label="LinkedIn" _hover={{ backgroundColor: "gray.100" }} />
          <IconButton as="a" href="https://twitter.com/patialaprats" icon={<FaTwitter />} aria-label="Twitter" _hover={{ backgroundColor: "gray.100" }} />
        </HStack>
      </VStack>

    <Footer />
    </Box>
  );
};

export default HomePage;