import React, { useState, useRef, useEffect } from "react";
import { Box, Heading, Image, Flex, Text, VStack, List, ListItem, ListIcon, Link, Center } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Link as ChakraLink } from "@chakra-ui/react";
import YouTube from "react-youtube";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import dishcareThumbnail from "dishcare_tn.jpg";

const sliderSettings = {
  
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};


const GifImage = ({ src, thumbnailSrc, alt, onClick, title}) => {
  const [playing, setPlaying] = useState(false);

  const handleMouseEnter = () => {
    setPlaying(true);
  };

  const handleMouseLeave = () => {
    setPlaying(false);
  };

  const handleTouchStart = () => {
    setPlaying(true);
  };

  const handleTouchEnd = () => {
    setPlaying(false);
  };

  return (
    <Flex direction="column" alignItems="center">
        <Box
          position="relative"
          boxSize="150px"
          borderRadius="md"
          onClick={onClick}
        >
          <Image
            justifyItems="center"
            src={thumbnailSrc}
            alt={alt}
            boxSize="100%"
            objectFit="cover"
            borderRadius="md"
            cursor="pointer"
            position="absolute"
            top="0"
            left="0"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            visibility={playing ? "hidden" : "visible"}
          />
          <Image
            justifyItems="center"
            src={src}
            alt={alt}
            boxSize="100%"
            objectFit="cover"
            borderRadius="md"
            position="absolute"
            top="0"
            left="0"
            cursor="pointer"
            visibility={playing ? "visible" : "hidden"}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          />
        </Box>
      <Box>
        <Text fontSize="sm" fontWeight="bold" whiteSpace="pre-wrap">
          {title}
        </Text>
      </Box>
    </Flex>
  );
};


const ProjectLayout = ({ projectTitle, projectDescription, publications, gifs }) => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isVideoAvailable, setIsVideoAvailable] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);
  const gifContainerRef = useRef(null);

  const scrollLeft = () => {
      setScrollPosition(scrollPosition - 150);
  };

  const scrollRight = () => {
      setScrollPosition(scrollPosition + 150);
  };

  const handleWheel = (e) => {
    e.preventDefault();
    if (e.deltaY < 0) {
      scrollLeft();
    } else {
      scrollRight();
    }
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX - touchEndX > 75) {
      scrollRight();
    } else if (touchStartX - touchEndX < -75) {
      scrollLeft();
    }
  };

  const textBoxRef = useRef(null);
  const [gifContainerWidth, setGifContainerWidth] = useState("100%");
  
  useEffect(() => {
    if (gifs[0].videoId) {
      setSelectedVideo(gifs[0].videoId);
      setIsVideoAvailable(true);
    } else {
      setSelectedImage(gifs[0].src);
      setIsVideoAvailable(false);
    }
  }, [gifs]);
  const handleGifClick = (videoId, src, link) => {
    if (videoId) {
      setIsVideoAvailable(true);
      setSelectedVideo(videoId);
    } else {
      setIsVideoAvailable(false);
      setSelectedImage(src);
    }
  };
 
  const videoOptions = {
    playerVars: {
      autoplay: 0,
      controls: 1,
      disablekb: 1,
      loop: 0,
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
    },
    width:"100%",
    height: "600px",
  };
  useEffect(() => {
    if (textBoxRef.current) {
      setGifContainerWidth(textBoxRef.current.clientWidth);
    }
  }, [textBoxRef]);

  const sliderSettings = {
  
    dots: true,
    infinite: gifs.length > 5,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <VStack
      alignItems="center"
      spacing={4}
      marginLeft={{ base: "0rem", md: "6rem" }}
      marginRight={{ base: "0rem", md: "6rem" }}
    >
      <Box
        backgroundColor="white"
        borderRadius="md"
        boxShadow="0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06)"
        padding="1rem"
        width={{ base: "100%", md: "100%" }}
        ref={textBoxRef}
      >
        <VStack spacing={4} w="100%">
          <Center>
            <Link href={projectTitle.link} isExternal color="teal.500">
              <Heading fontSize={{ base: "6xl", md: "6xl" }} fontWeight="bold">
                {projectTitle.text}
              </Heading>
            </Link>
          </Center>
          <VStack alignItems="flex-start" spacing={4} textAlign="left">
            <Text fontSize={{ base: "sm", md: "2xl" }} fontWeight="semibold" fontStyle="italic">
              {projectDescription}
            </Text>
    
            <Box position="relative" alignItems="center" justifyItems="center"  width={{ base: "100%", md: "100%" }} borderRadius="md" overflow="hidden">
          {isVideoAvailable ? (
            <YouTube videoId={selectedVideo} opts={videoOptions}  />
          ) : (
           
            <Image src={selectedImage} alt="Selected thumbnail"
            width="auto"
            height = "450px"
            borderRadius="md"
            cursor="pointer"
            position="relative"
            left="50%"
            transform="translate(-50%, 0%)"
            />

          )}
        </Box>
          </VStack>
        </VStack>
      </Box>
      <Box
        width={{ base: "100%", md: "100%" }}
        ref={gifContainerRef}
        onWheel={handleWheel}
        // onTouchStart={handleTouchStart}
        // onTouchMove={handleTouchMove}
        // onTouchEnd={handleTouchEnd}
      >
        <Slider {...sliderSettings}>
          {gifs.map((gif, index) => ( 
            <GifImage
              key={index}
              src={gif.src}
              thumbnailSrc={gif.thumbnailSrc}
              alt={gif.alt}
              title={gif.text}
              onClick={() => handleGifClick(gif.videoId, gif.src)}
            />
          ))}
        </Slider>
      </Box>
      <VStack spacing="16px"></VStack>
      {
      publications.length > 0 && (
    <Box
      backgroundColor="white"
      borderRadius="md"
      boxShadow="0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06)"
      padding="1rem"
      width={{ base: "100%", md: "auto" }}
      ref={textBoxRef}
    >
      <Heading
        as="h3"
        size="md"
        fontSize={{ base: "sm", md: "4xl" }}
        fontWeight="bold"
        mb={2}
      >
        Featured Work
      </Heading>
      <List spacing={2} fontSize={{ base: "xl", md: "2xl" }}>
        {publications.map((publication, index) => (
          <ListItem key={index}>
            <ListIcon as={CheckCircleIcon} color="green.500" />
            <Link href={publication.link} isExternal color="teal.500">
              {publication.title}
            </Link>
            <Text display="block">{publication.authors}</Text>
            <Text display="block">{publication.conference}</Text>
          </ListItem>
        ))}
      </List>
    </Box>
  )
        }
    </VStack>
  );
};

const DishcareProject = () => {
  const projectTitle = {
    text: "Dishcare",
    link: "https://dish.care",
  };

  const projectDescription = `I am a Co-Founder of Dishcare, an autonomous robotic dishwasher aimed at revolutionizing the dishwashing industry. Our custom-built robot, equipped with a specialized gripper, employs advanced computer vision techniques to enable 6D pose estimation and dirt detection. This cutting-edge technology allows for a robust pick-up and closed-loop cleaning process, ultimately leading to a 50% reduction in energy and water consumption compared to top-of-the-line dishwashers. As a key contributor to the project, I was responsible for designing, building, and programming the alpha prototype.`;

  const publications = [
    {
      title: "Patent: Autonomous Dishwasher (17/687,332; Pending)",
      authors: "Authors: Pratik Chawla, Sri Rama Prasanna Pavani",
      link: "https://drive.google.com/file/d/1QoO4LnbtDCenF1xeSGtnndc3T3DPQJVR/view?usp=sharing",
    },
    {
      title: "Patent: Rolling Grasp End Effector (17/713,838; Pending)",
      authors: "Authors: Pratik Chawla, Sri Rama Prasanna Pavani",
      link: "https://drive.google.com/file/d/1OVifNksOBiDf6agVhAHXiFppki5NyQY2/view?usp=share_link",
    },
    {
      title: "Report: Robotic End Effector for Autonomous Cleaning of Dishes in Homes and Offices",
      authors: "Authors: Sri Rama Prasanna Pavani, Pratik Chawla, and Anurag Agrawal",
      conference: "Dishcare, 2022",
      link: "https://drive.google.com/file/d/1JPHGbYTht8I6DwKoNhjotcAXyMtDi3rA/view?usp=sharing",
    },
    {
      title: "Report: Minimizing Pathogens on Everyday Dishes With an Autonomous Dishwasher",
      authors: "Authors: Sri Rama Prasanna Pavani, Shrutidhara Kakoti, and Pratik Chawla",
      conference: "Dishcare, 2022",
      link: "https://drive.google.com/file/d/1GtSgzthkYT8uhbntksFMPN8L6gUEvKmd/view?usp=share_link",
    },
  ];
  const gifs = [
    { src: "dishcare_ani.jpg", thumbnailSrc: "dishcare_ani.jpg", alt: "Sample Gif 4", text: "12/21\nEnvisioned design", videoId: "7RiLpxEcmdo" },
    { src: "dishcare_alpha.jpg", thumbnailSrc: "dishcare_alpha.jpg", alt: "dishcare_ships", text: "1/23\nReady for Public demos", videoId: "NJi4vIFSSP8" },
    { src: "grasp_plan.jpg", thumbnailSrc: "grasp_plan.jpg", alt: "grasp_plans", text: "11/22\nOptimal Grasp Planning", videoId: "M0u1OEaR8qg" },
    { src: "build_speed.jpg", thumbnailSrc: "build_speed.jpg", alt: "faster_clean", text: "10/22\nDishcare cleans 80% faster ", videoId: "gKb8Jq5TVWQ" },
    { src: "fallen_grasp.jpg", thumbnailSrc: "fallen_grasp.jpg", alt: "compact_gripper", text: "8/22\nCompact gripper", videoId: "nKG6b9ucRus" },
    { src: "conserve_demo.jpg", thumbnailSrc: "conserve_demo.jpg", alt: "Sample Gif 4", text: "6/22\nWater & Energy Conservation", videoId: "Sof6cUwv11Y" },
    { src: "dirt_target.jpg", thumbnailSrc: "dirt_target.jpg", alt: "Sample Gif 4", text: "5/22\nDirt Targetting", videoId: "2KN2tbj6Ga0" },
    { src: "custom_robot.jpg", thumbnailSrc: "custom_robot.jpg", alt: "Sample Gif 4", text: "2/22\nFirst custom robot", videoId: "-b4YNequIok" },
    { src: "proof_concept.jpg", thumbnailSrc: "proof_concept.jpg", alt: "Sample Gif 4", text: "1/22\nProof of concept", videoId: "cVVyoYjPejQ" },
    
  ];
  return <ProjectLayout projectTitle={projectTitle} projectDescription={projectDescription} publications={publications} gifs={gifs} />;
};

const Plain2FunProject = () => {
  // Define your data for Plain2FunProject here
  const projectTitle = { text: "Plain2Fun", link: "https://www.researchgate.net/profile/Ke-Huo/publication/325707812_Plain2Fun_Augmenting_Ordinary_Objects_with_Interactive_Functions_by_Auto-Fabricating_Surface_Painted_Circuits/links/5b2c5e35aca2720785d660bf/Plain2Fun-Augmenting-Ordinary-Objects-with-Interactive-Functions-by-Auto-Fabricating-Surface-Painted-Circuits.pdf" };
  const projectDescription = `During my Master's project at Purdue University's C Design Lab, under the guidance of Dr. Karthik Ramani, I worked on Plain2Fun, a unique workflow that transforms everyday objects like bottles, vases, and mugs into functional items such as alarm clocks, status indicators, or temperature indicators. This was achieved by developing a custom four-axis machine capable of holding, rotating, and scanning near-cylindrical objects, while drawing circuits on them through an easy-to-use graphical user interface (GUI).

  My contributions to the project included designing and building the four-axis machine, writing firmware code for microcontrollers that users could attach to the objects, and creating an interface for connecting rigid electronics to the drawn circuits, effectively bringing the objects to life with the desired functionality.`;
  const publications = [
    {
      title: "Plain2Fun: Augmenting Ordinary Objects with Interactive Functions by Auto-Fabricating Surface Painted Circuits",
      authors: "Authors: Tianyi Wang, Ke Huo, Pratik Chawla, Guiming Chen, Siddharth Banerjee, and Karthik Ramani.",
      conference: "DIS 2018: Proceedings of the 2018 Designing Interactive Systems Conference",
      link: "https://dl.acm.org/doi/abs/10.1145/3196709.3196791",
    },
    {  
      title: "Plain2Fun: Augmenting Ordinary Objects with Surface Painted Circuits",
      authors: "Authors: Tianyi Wang, Ke Huo, Pratik Chawla, Guiming Chen, Siddharth Banerjee, and Karthik Ramani.",
      conference: "Extended Abstracts of the 2018 CHI Conference on Human Factors in Computing Systems, pp. 1-6. 2018.",
      link: "https://dl.acm.org/doi/abs/10.1145/3170427.3188655",
    },
    {
      title: "Overprint, project update",
      authors: "Author: Pratik Chawla",
      link: "https://drive.google.com/file/d/1Sj1F9A409TKgc-gG2niMjEWBRlVs8eLK/view?usp=sharing",
    }

  ];
  
  const gifs = [
    { src: "PLAIN2FUN.gif", thumbnailSrc: "plain2fun.jpg", alt: "Sample Gif 1", text: "4 axis circuit printing machine" , videoId: "mIlBj9MfFP8" },
    { src: "status_pointer.gif", thumbnailSrc: "status.jpg", alt: "Sample Gif 2", text: "Water Bottle >> Status Indicator"},
    { src: "alarm_clock.gif", thumbnailSrc: "chlorox.jpg", alt: "Sample Gif 3", text: "Chlorox Container >> Alarm Clock" },
  ];
  return <ProjectLayout projectTitle={projectTitle} projectDescription={projectDescription} publications={publications} gifs={gifs} />;
};

const SkyvenProject = () => {
  
  // Define your data for SkyvenProject here
  const projectTitle = { text: "Skyven", link: "https://www.skyven.co/" };
  const projectDescription = `As a Product Development Engineer at Skyven Technologies, I was responsible for building and evaluating solar heating receivers, ultimately achieving a systemwide efficiency of 65%. My role also involved performing various reliability tests, including accelerated heat, vibration, and pressure testing, to ensure the durability and reliability of our products. In addition, I contributed to process and machine automation by working on projects such as the sun tracker, slip roll, and automatic sticker removal, which helped streamline operations and increase overall productivity.`;
  const publications = [
    {
      title: "Skyven Delivers 50 Solar Heating Systems to Cornell University",
      link: "https://www.ststartup.com/2021/04/08/startup-installs-innovative-solar-tech-at-cornell/",
    }
  ];
  const gifs = [
    { src: "skyven.jpg", thumbnailSrc: "skyven.jpg", alt: "Sample Gif 1", text: "Product Development Engineer"},
  ];
  return <ProjectLayout projectTitle={projectTitle} projectDescription={projectDescription} publications={publications} gifs={gifs} />;
}

const PromaxoProject = () => {
  // Define your data for SkyvenProject here
  const projectTitle = { text: "Promaxo", link: "https://www.promaxo.com/" };
  const projectDescription = `As an Advanced Hardware Engineer at Promaxo, I took charge of designing, building, and testing the entire electronics rack for an in-office MRI machine, ensuring its efficient functionality. I was responsible for maintaining the hardware package, which included 3D models, 2D drawings, and over 2000 components managed using Product Data Management (PDM) tools. My role also involved hands-on building and testing of high-power and low-power electrical systems to guarantee the performance and reliability of our products. Furthermore, I successfully managed two interns and collaborated with a diverse team of scientists, contract manufacturers, and engineers to achieve our project goals.`;
  const publications = [];
  const gifs = [
    { src: "promaxo.png", thumbnailSrc: "promaxo.png", alt: "Sample Gif 1", text: "Advanced Hardware Engineer"},
  ];
  return <ProjectLayout projectTitle={projectTitle} projectDescription={projectDescription} publications={publications} gifs={gifs} />;
}

const DfAMProject = () => {
  // Define your data for SkyvenProject here
  const projectTitle = { text: "DfAM", link: "https://drive.google.com/file/d/11B57f-mjWCJKKYfAxFe9CPG2qNxqAG39/view?usp=share_link" };
  const projectDescription = `The Design for Additive Manufacturing (DfAM) worksheet is a powerful tool that assists novice users in selecting and finalizing designs for additive manufacturing. It features an intuitive scoring system that can be utilized before sending any designs to the 3D printer, effectively helping users avoid common mistakes and pitfalls associated with additive manufacturing technologies.
  As a Teaching Assistant for a CAD and rapid prototyping class, I took the initiative to test the effectiveness of this worksheet by incorporating it into the students' final projects, where they were tasked with designing and building a toy. I developed the assignments, introduced the project, and collected and managed the data from over 40 students. Through careful analysis, I was able to demonstrate the effectiveness of the DfAM worksheet in guiding users through the additive manufacturing process and supporting our hypothesis.`;
  const publications = [
    {
      title: "2017 Editors’ Choice Paper Award: The Design for Additive Manufacturing Worksheet",
      conference: "ASME. JMD 2017",
      link: "https://asmejmd.org/2018/10/03/announcing-the-2017-editors-choice-award-and-honorable-mentions-2/",
      authors: "Authors: Joran W. Booth, Jeffrey Alperovich, Pratik Chawla, Jiayan Ma, Tahira N. Reid, Karthik Ramani"
    }, 
    {
      title: "The Design for Additive Manufacturing Worksheet",
      conference: "ASME. J. Mech. Des. October 2017",
      link: "https://asmedigitalcollection.asme.org/mechanicaldesign/article/139/10/100904/366998/The-Design-for-Additive-Manufacturing-Worksheet",
      authors: "Authors: Joran W. Booth, Jeffrey Alperovich, Pratik Chawla, Jiayan Ma, Tahira N. Reid, Karthik Ramani"
    }
  ];
  const gifs = [
    { src: "dfam.PNG", thumbnailSrc: "dfam.PNG", alt: "Sample Gif 1", text: "DfAM Worksheet"},
  ]
  return <ProjectLayout projectTitle={projectTitle} projectDescription={projectDescription} publications={publications} gifs={gifs} />;
}

const MAHAProject = () => {
  // Define your data for SkyvenProject here
  const projectTitle = { text: "MAHA Fluid Power", link: "https://docs.lib.purdue.edu/surf/2015/presentations/120/"};
  const projectDescription = `At the MAHA Fluid Power Lab as a Research Assistant, I focused on constructing a test rig to validate the model of a Variable Displacement Vane Pump. This intricate piece of hardware, consisting of numerous moving parts, required thorough analysis to develop a robust control system. My responsibilities included selecting and installing sensors such as pressure and LVDT to collect data for varying flow rates and displacements of the pump. I proposed hardware modifications for sensor integration and developed an algorithm to compile and visualize data from six different sensor streams, enabling accurate measurement of fluid pressure at every rotation of the pump.`;
  const publications = [
    {
      title: "Test Rig Design for Variable Displacement Vane Pump",
      conference: "Presented at SURF Symposium at Purdue University, 2015",
      link: "https://drive.google.com/file/d/1vhQFKcXQV2YW7oIyRQ68_72XdYnN4n5Q/view?usp=sharing",
      authors: "Authors: Pratik Chawla, Ryan Jenkins, Monika Ivantysynova"
    }
  ];
  const gifs = [
    { src: "vdvp.jpg", thumbnailSrc: "vdvp.jpg", alt: "Sample Gif 1", text: "Pump sensor placements"},
    { src: "VDVP.PNG", thumbnailSrc: "VDVP.PNG", alt: "Sample Gif 1", text: "Pump sensor fusion visualization"},

  ];
  return <ProjectLayout projectTitle={projectTitle} projectDescription={projectDescription} publications={publications} gifs={gifs} />;
}

const SDProject = () => {
  // Define your data for SkyvenProject here
  const projectTitle = { text: "ArielDrone boat that saves lives!"};
  const projectDescription = `I am a Co-Founder of MAHA, a startup that aims to make the world a better place by making it easier for people to find and play board games. We are currently developing a mobile app that will allow users to find board games that they will enjoy based on their preferences and past experiences. As a key contributor to the project, I am responsible for designing and developing the mobile app.`;
  const publications = [
    {
      title: "DfAM Delivers 50 Solar Heating Systems to Cornell University",
      link: "https://www.ststartup.com/2021/04/08/startup-installs-innovative-solar-tech-at-cornell/",
    }
  ];
  const gifs = [
    { src: "PLAIN2FUN.gif", thumbnailSrc: "dishcare_tn.jpg", alt: "Sample Gif 1", text: "I" , videoId: "mIlBj9MfFP8" },
  ];
  return <ProjectLayout projectTitle={projectTitle} projectDescription={projectDescription} publications={publications} gifs={gifs} />;
}

const SelectedProject = ({ project }) => {
  if (project === "Plain2Fun") {
    return <Plain2FunProject />;
  }

  if (project === "Dishcare") {
    // return <ModelViewer />;
    return <DishcareProject />;
  }

  
  if (project === "Skyven") {
    // return <ModelViewer />;
    return <SkyvenProject />;
  }

  if (project === "Promaxo") {
    return <PromaxoProject />;
  }

  if (project === "DfAM") {
    return <DfAMProject />;
  }

  if (project === "MAHA") {
    return <MAHAProject />;
  }


  return <div>More info on {project}</div>;
};


const Footer = () => (
  <Box
    as="footer"
    display="flex"
    justifyContent="center"
    alignItems="center"
    backgroundColor="transparent"
    padding="1rem"
    marginTop="2rem"
  >
    <Text color="blue.900">Created by Pratik Chawla &copy; {new Date().getFullYear()}</Text>
  </Box>
);

const PortfolioPage = () => {
  const [selectedProject, setSelectedProject] = useState("Dishcare");
  const [scrollPosition, setScrollPosition] = useState(0);
  const textBoxRef = useRef(null);
  const [gifContainerWidth, setGifContainerWidth] = useState("100%");
  

  useEffect(() => {
    if (textBoxRef.current) {
      setGifContainerWidth(textBoxRef.current.clientWidth);
    }
  }, [textBoxRef]);


  return (
    <Box
      style={{
        backgroundImage: "linear-gradient(180deg, #ffffff 20%, #00eaff 100%)",
      }}
      minHeight="100vh"
    >
      <Box
        width="100%"
        // overflowX="scroll"
        height="200px"
        marginTop="2rem"
       
      >
      <Slider {...sliderSettings}>
          <GifImage
            src="wash.gif"
            thumbnailSrc="dishcare.gif"
            alt="Dishcare"
            title="Dishcare"
            onClick={() => setSelectedProject("Dishcare")}
          />
          <GifImage
            src="PLAIN2FUN.gif"
            thumbnailSrc="plain2fun.jpg"
            alt="Plain2Fun"
            title="Plain2Fun"
            onClick={() => setSelectedProject("Plain2Fun")}
          />
          {/* <GifImage
            src="mechatronics_thumbnail.gif"
            thumbnailSrc="dishcare_tn.jpg"
            alt="Mechatronics"
            title="Mechatronics"
            onClick={() => setSelectedProject("Mechatronics")}
          /> */}
          <GifImage
            src="skyven.jpg"
            thumbnailSrc="skyven.jpg"
            alt="Skyven"
            title="Skyven"
            onClick={() => setSelectedProject("Skyven")}
          />
          <GifImage
            src="promaxo.png"
            thumbnailSrc="promaxo.png"
            alt="Promaxo"
            title="Promaxo"
            onClick={() => setSelectedProject("Promaxo")}
          />
          <GifImage
            src="dfam.PNG"
            thumbnailSrc="dfam.PNG"
            alt="DfAM"
            title="DfAM"
            onClick={() => setSelectedProject("DfAM")}
          />
          <GifImage
            src="VDVP.PNG"
            thumbnailSrc="vdvp.jpg"
            alt="MAHA"
            title="MAHA"
            onClick={() => setSelectedProject("MAHA")}
          />
        </Slider>
      </Box>
      <Box mt={8} textAlign="center">
        <SelectedProject project={selectedProject} />
      </Box>
      <Footer />
    </Box>
  );
};

export default PortfolioPage;